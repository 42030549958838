.VideoWidgetItem {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-image-container {
  .center-ing {
    justify-content: center;
    flex-direction: row-reverse;
  }
}
.videoimage-container {
  padding: 50px 0px;
  background-size: 0 0;
}

@media only screen and (max-width: 981px) {
  .videoimage-container {
    padding: 15px 0px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .video-image-container {
    .center-ing {
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (min-width: 981px) {
  .videoimage-container {
    background-image: none !important;
  }
}
