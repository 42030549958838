.show {
  display: block;
}
.hide {
  display: none;
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}
.button-show-now {
  border-width: 0px;
  display: inline-block;
  background-color: #de0000;
  text-align: right;
  border-radius: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 7px 30px;
  margin-right: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.app-prod-list-header {
  padding: 50px 0px 0px 0px;
  text-align: center;
}
a.button-show-now {
  text-decoration: none;
  &:hover {
    color: white;
  }
}
.app-body {
  flex: 1 0 auto;
  margin-top: 60px;
}
.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.app-footer {
  flex-shrink: 0;
}
.text-select-disable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.popup-responsive {
  @media only screen and (max-width: 992px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px !important;
    }
  }
}

.form-label-bold {
  font-weight: bold;
}

.p-b-5p {
  padding-bottom: 5%;
}

.flex-dir-col {
  flex-direction: column;
}

.p-t-3p {
  padding-top: 3%;
}

.m-r-10 {
  margin-right: 10px;
}

.p-l-r-10 {
  padding: 0px 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.app-bold-label {
  font-weight: bold;
}

// .custon-radio {
//   [type='radio']:checked,
//   [type='radio']:not(:checked) {
//     position: absolute;
//     left: -9999px;
//   }
//   [type='radio']:checked,
//   [type='radio']:not(:checked) {
//     position: relative;
//     padding-left: 25px;
//     cursor: pointer;
//     line-height: normal;
//     display: inline-block;
//     color: #80299f;
//     font-size: 12px;
//   }
//   [type='radio']:checked,
//   [type='radio']:not(:checked) {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 17px;
//     height: 17px;
//     border: 1px solid #d9d9d9;
//     border-radius: 100%;
//     background: #fff;
//   }
//   [type='radio']:checked,
//   [type='radio']:focus {
//     border: 1px solid #80299f;
//   }
//   [type='radio']:checked + label {
//     color: #80299f;
//     font-weight: 600;
//   }
//   [type='radio']:checked,
//   [type='radio']:not(:checked) {
//     content: '';
//     width: 11px;
//     height: 11px;
//     background: #80299f;
//     position: absolute;
//     top: 4.3px;
//     left: 4.1px;
//     border-radius: 50%;
//     -webkit-transition: all 0.2s ease;
//     transition: all 0.2s ease;
//   }
//   [type='radio']:not(:checked) {
//     opacity: 0;
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   }
//   [type='radio']:checked {
//     opacity: 1;
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
// }

.app-checkout-button-disabled {
  background-image: linear-gradient(180deg, #e4b2b2 0%, #e4979b 100%) !important;
}

.app-error-messages {
  color: #dc3545;
  display: inline-block;
}



#CybotCookiebotDialog {
 
  opacity: 0.9 !important;
  background: #b50404  !important;
  color: white !important;
  
}


#CybotCookiebotDialogBodyButtonAccept {
  background-color: #b50404 !important;;
  border: 1px solid #ffffff !important;;
}


#CybotCookiebotDialogBodyContentTitle {

  color: #f1e5e5 !important;
 
}

#CybotCookiebotDialogBodyLevelDetailsButton {

  color: #ffffff !important;
  
}

#chatbot-chat {
  z-index: 8888 !important;
}

