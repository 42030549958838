.app-product-card {
  .background-group-color-black {
    background-color: #272727;
    padding: 30px 0px;
  }
  .background-group-color {
    background-color: white;
    padding: 1px 0px;
  }
  .custom-container{
    max-width: 1300px;
  }

  .title-container {
    height: 55px;
  }

  .app-product-cards {
    .column-card {
      padding: 10px;
      width: 33.33%;
    }
    .card-body-whole:hover {
      box-shadow: 0 3px 10px 5px #aaa !important;
      transition: box-shadow 0.3s ease-in-out !important;
      transition-duration: 500ms !important;
      transition-timing-function: ease-in-out !important;
    }
    .card-body {
      height: 450px;
      display: flex;
      align-items: center;
    }

    .card-img-size {
      background-repeat: no-repeat;
      background-size: contain;
      height: 30px;
      background-position-y: center;
    }
    .card {
      border: none !important;
    }

    .product-card-img-size {
      position: relative;
      height: 87%;
      width: auto;
    }

    .product-left-element {
      width: 50%;
      padding: 5px;
      padding-right: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .product-right-element {
      width: 50%;
      height: 100%;
      max-height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      padding-left: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .text-card-product {
      font-size: 13px !important;
      color: #666;
      max-height: 162px;
      margin-bottom: 10px;
      overflow: hidden;
    }

    .button-card-product {
      background: #EC2524 radial-gradient(circle,transparent 1%,#EC2524 1%) center/15000%!important;
      box-shadow: 0 3px 6px rgba(0,0,0,.4)!important;
      border-radius: 5px;
      line-height: 1;
      padding: 10px 18px;
      border: none;
      -webkit-box-flex: 1;
      text-align: center;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin-top: auto;
      &:hover {
        background-color: #e00303;
      }
    }

    .product-button-text {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1.87px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      @media (max-width: 1185px) {
        font-size: 10px;
      }
    }
    @media only screen and (max-width: 992px) {
      .button-card-product {
        font-size: 10px;
      }
    }
  }

  .app-product-accordian {
    .app-img-width {
      display: flex;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }

    .capitalize-name{
      text-transform: capitalize;
      border-bottom: none !important;
      // background-color: white;
      margin: 3px 0;
    }

    .bg-color-accordion{
      background-color: white;
    }

    .app-prod-details {
      background-color: #242424;
      padding: 20px 6%;
      .app-prod-title {
        color: #fff;
        font-size: 28px;
      }
      .app-prod-desc {
        color: #fff;
        line-height: 1.5em;
        font-weight: 300;
        margin-bottom: 10px;
      }
      .button-card-product {
        text-transform: capitalize;
        background: #EC2524 radial-gradient(circle,transparent 1%,#EC2524 1%) center/15000%!important;
        box-shadow: 0 3px 6px rgba(0,0,0,.4)!important;
        border-radius: 5px;
        padding: 12px 30px;
        border: none;
        -webkit-box-flex: 1;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .carditem-price {
    position: relative;
    margin-bottom: 10px;
    h3, h4, p {
      margin: 0;
    }
    .affirm-logo {
      position: absolute;
      height: 18px;
      padding: 0;
      margin: 0;

    }
    .affirm-mb {
      padding-left: 5px;
    }
  }

  .mobile-price {
    color: white;
    padding: 15px 0;
  }

  .carditem-descp{
    font-size: 13px;
    color: #666666;
  }
  .card-body-padding{
    padding: 1rem 0.5rem;
  }
  .cardItem-sub-title{
    font-size: 16px;
    color: #333333;
    margin: 0;
  }
}

.view-prod-accordian{
  font-size: 34px;
  font-weight: 300;
  line-height: 1.25;
  padding: 15px 15px 15px 15px;
}

@media (max-width: 480px){
  .view-prod-accordian{
    text-align: center;
  }
}